import Axios from "axios";
import { BackTop, Notify } from "vant";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import routes from "./router";

import "vant/lib/index.css";

const router = createRouter({ routes, history: createWebHistory() });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "数据查询服务";
	next();
});

const app = createApp(App);

const axios = Axios.create({
	baseURL: "/api",
});

axios.interceptors.request.use(
	function (config) {
		const jwtToken = localStorage.getItem("jwtToken");
		jwtToken ? (config.headers.Authorization = `Bearer ${jwtToken}`) : null;
		config.headers.Accept = "application/json";
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const credentialsExpired = Array.of(1001, 1002, 1003, 1004);
axios.interceptors.response.use((res) => {
	const { config, data: result } = res;

	// 拦截调用管理端API
	if (
		config.url.startsWith("/admin") &&
		credentialsExpired.includes(result.code)
	) {
		router.replace({ name: "AdminLogin" });
		return Promise.reject(new Error(`${result.message}(${result.code})`));
	}
	return res;
});

app.config.globalProperties.$http = axios;

app.use(BackTop).use(Notify);
app.use(router);
app.mount("#app");
